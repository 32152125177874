import Image, { ImageProps, ImageLoader } from "next/image";
import { CSSProperties, useRef } from "react";

export type ImgProps = ImageProps & {
  style?: CSSProperties;
};

const loader: ImageLoader = ({ src, width }) => {
  const imagePrefix =
    width > 720
      ? "large"
      : width > 640
      ? "medium"
      : width > 300
      ? "small"
      : "thumbnail";

  const filePath = src.endsWith(".svg")
    ? src
    : src.replace("/uploads/", `/uploads/${imagePrefix}_`);

  return filePath;
};

const Img = (props: ImgProps) => {
  const failedOnce = useRef(false);
  const imageRef = useRef<HTMLImageElement>(null);

  return (
    <Image
      ref={imageRef}
      onError={() => {
        if (failedOnce.current) {
          return;
        }

        if (imageRef.current) {
          imageRef.current.src = `${process.env.NEXT_PUBLIC_API_URL}${props.src}`;
          imageRef.current.srcset = "";
        }

        failedOnce.current = true;
      }}
      loader={loader}
      {...props}
    />
  );
};

export default Img;
